import { enum_sportType } from "../../typings/db-models.d"

class EmailTemplateSubstitutions {
  constructor(
    // fantasy uses
    public splnid?: string,
    // tslint:disable-next-line: variable-name
    public first_name?: string,
    public owneremail?: string,
    // tslint:disable-next-line: variable-name
    public product_name?: string,
    // tslint:disable-next-line: variable-name
    public league_abbrev?: string,
    // tslint:disable-next-line: variable-name
    public mi_id?: number,
    // tslint:disable-next-line: variable-name
    public invite_link?: string,
    // tslint:disable-next-line: variable-name
    public commissioner_name?: string,
    // tslint:disable-next-line: variable-name
    public SPORT?: enum_sportType,
    // ---------------------------------------
    // ones we added:
    public dynamicHtml?: string,
    public entryUrl?: string,
    public poolUrl?: string,
    public ttag?: string,
    public ftag?: string,
    public poolName?: string,
  ) {}
}

export default EmailTemplateSubstitutions
