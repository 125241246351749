import { useMutation, useQuery } from "@apollo/client"
import { Accordion, AccordionDetails, Typography, AccordionSummary } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons"
import React from "react"
import { useForm } from "react-hook-form"
import { RouteChildrenProps } from "react-router"
import { AdminSQLQuery, AdminSQLQueryVariables } from "../../../__generated__/AdminSQLQuery"
import { adminUpsertGameInstance, adminUpsertGameInstanceVariables } from "../../../__generated__/adminUpsertGameInstance"
import EmailTemplateSubstitutions from "../../../common/email-template-substitutions"
import { humanize } from "../../../common/string-utils"
import Code from "../../components/Code"
import InfoSection from "../components/InfoSection"
import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import { ADMIN_SQL_QUERY, ADMIN_UPSERT_GAME_INSTANCE_MUTATION } from "../queries"

const inputLabelProps = {
  shrink: true,
}
const useStyles = makeStyles(() =>
  createStyles({
    hiddenInput: {
      display: "none",
    },
    expansionArea: {
      display: "block",
    },
  }),
)

function SeasonForm({ season, mutate, refetch, isSaving }) {
  const classes = useStyles()
  const columnNames = Object.keys(season).filter((k) => /(email|^id$)/i.test(k))
  const { register, handleSubmit } = useForm()
  const onSubmit = async (data) => {
    await mutate({
      variables: {
        mapping: {
          Season: Object.assign({}, data, { id: parseInt(data.id) }),
        },
      },
    })
    // refetch original query
    await refetch()
  }
  const panelId = season.id
  // console.dir(columnNames)
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${panelId}-content`} id={`${panelId}-header`}>
          <Typography
            title={`${season.description} - ${season.year} ${season.season} Season`}
          >{`${season.year} ${season.productAbbrev} (MPID: ${season.masterProductId})`}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionArea}>
          {columnNames.map((columnName) => {
            const placeholder =
              (/image/i.test(columnName) && `ex: https://www.cbssports.com/images/email/graphics/200210_conferencebracketsheaderimgdesktop.png`) ||
              (/url/i.test(columnName) && `ex: https://mayhem.cbssports.com/email/mayhem/spln/welcome-bpm-conf-commish`) ||
              (/ftag/i.test(columnName) && `ex: SPM-16-10acb5f`) ||
              undefined
            const label = humanize(columnName)
            const inputType = /url/i.test(columnName) ? `url` : "text"
            return (
              <TextField
                key={columnName}
                inputRef={register}
                label={label}
                fullWidth={true}
                type={inputType}
                name={columnName}
                placeholder={placeholder}
                defaultValue={season[columnName] || undefined}
                autoComplete="off"
                margin="normal"
                className={columnName === "id" ? classes.hiddenInput : undefined}
                InputLabelProps={inputLabelProps}
              />
            )
          })}
          <Button variant="contained" color="primary" disabled={isSaving} type="submit">
            {isSaving ? `Saving...` : `Save`}
          </Button>
        </AccordionDetails>
      </Accordion>
      <br />
    </form>
  )
}

function Analytics({ location, history }: RouteChildrenProps) {
  // const segmentsQuery = useQuery<AdminAllSegmentsQuery>(ADMIN_ALL_SEGMENTS_QUERY, {});
  // const segments = segmentsQuery.data?.allSegments
  const response = useQuery<AdminSQLQuery, AdminSQLQueryVariables>(ADMIN_SQL_QUERY, {
    variables: {
      statement: `SELECT *, Season.id AS id from Season LEFT JOIN GameInstance ON GameInstance.id = Season.gameInstanceId ORDER BY Season.id DESC`,
      // statement: `SELECT *, Segment.id AS segmentId FROM Segment LEFT JOIN Season ON Season.id = Segment.seasonId LEFT JOIN GameInstance ON GameInstance.id = Season.gameInstanceId ORDER BY Segment.id DESC`
    },
  })
  const [mutate, mutationResult] = useMutation<adminUpsertGameInstance, adminUpsertGameInstanceVariables>(ADMIN_UPSERT_GAME_INSTANCE_MUTATION)
  // console.log('response')
  // console.dir(response)
  const seasons = response.data?.sql
  const klass = new EmailTemplateSubstitutions()
  const parameters = JSON.stringify(
    Object.keys(klass).map((k) => `$${k}`),
    undefined,
    2,
  )
  return (
    <Layout title={`Marketing - Email Templates`}>
      <Typography variant="h4" component="h2">
        Information
      </Typography>
      <br />
      <InfoSection id="1" heading="Using Hosted Templates">
        <p>Every email in the picks platform is by default built via the generic template in the codebase, which we attempt to keep up to date.</p>
        <p>
          However, where possible, we attempt to allow <strong>"hosted" templates</strong>: which can be HTML templates outside the Picks Platform.
        </p>
      </InfoSection>
      <InfoSection id="2" heading="Variable Substitution">
        <p>We automatically try to fill in as many of the following default variables as possible.</p>
        <p>If any are missing in the hosted template, contact a developer to assist in figureing out why</p>
        <p>
          For all URL variables, <code>ttags</code> and <code>ftags</code> (if provided) are automatically added by the Picks Platform
        </p>
        <p>The following parameters are currently provided:</p>
        <Code>{parameters}</Code>
      </InfoSection>
      <Typography variant="h4" component="h2">
        Seasons
      </Typography>
      <br />
      {(seasons &&
        seasons.map((season) => {
          return <SeasonForm season={season} mutate={mutate} refetch={response.refetch} isSaving={mutationResult.loading} key={season.id} />
        })) || <LoadingView />}
    </Layout>
  )
}

export default Analytics
